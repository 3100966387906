import React from 'react';
import { Link } from 'react-scroll';

import { sectionsMetaData } from '../../../data';

export const HeaderLinks = (): JSX.Element => {
  return (
    <nav className="list-none hidden md:flex items-center gap-8">
      {sectionsMetaData.map(link => (
        <li key={link.id}>
          <Link
            className="font-medium text-brandWhite hover:text-brandWhite/60 transition duration-100 cursor-pointer"
            to={link.id}
            spy={true}
            smooth={true}
            duration={500}
          >
            {link.name}
          </Link>
        </li>
      ))}
    </nav>
  );
};
