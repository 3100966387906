import { LogReturn } from 'amplitude-js';

import { logAmplitudeEvent } from '@features/common';

import { CCEAnalyticsEvent } from './types';

export const logCCEOpenedAmplitudeEvent = (): LogReturn => logAmplitudeEvent(CCEAnalyticsEvent.CCE_OPENED);

export const logCCEStartedTournamentRegistrationAmplitudeEvent = (): LogReturn =>
  logAmplitudeEvent(CCEAnalyticsEvent.STARTED_TOURNAMENT_REGISTRATION);

export const logCCEClickOnPlaydexTournamentsLinkAmplitudeEvent = (): LogReturn =>
  logAmplitudeEvent(CCEAnalyticsEvent.CLICK_ON_PLAYDEX_TOURNAMENTS_LINK);
