import { useCceContentData } from '@features/cce';
import { ReactComponent as CceFooterLogo } from '@public/cce/footer-section/ccelogo.svg';

import { cceFooterData } from './cceFooterData';

export const CceFooter = (): JSX.Element => {
  const { footerSocialLinks } = useCceContentData();
  const { webLinks, socialLinksIconByType } = cceFooterData;
  return (
    <footer className="w-full px-6 md:px-4 md:h-40 border-t-1 border-[#22222F]">
      <div className="max-w-[1240px] mx-auto pt-5.5 flex flex-col md:flex-row justify-between gap-6 md:gap-0">
        <CceFooterLogo className="w-[66px] md:w-[100px] aspect-square" />
        <section className="py-4 flex flex-col gap-6">
          <div className="flex flex-col md:flex-row gap-4 md:gap-8 md:items-center">
            {webLinks.map(({ href, label }) => (
              <a
                target="_blank"
                className="text-labelSecondary hover:text-labelPrimary transition text-sm md:text-base"
                key={label}
                href={href}
                rel="noreferrer"
              >
                {label}
              </a>
            ))}
          </div>
          <div className="flex gap-6 md:ml-auto">
            {footerSocialLinks
              .filter(link => Boolean(link.url))
              .map(({ url, type }, index) => {
                const Icon = socialLinksIconByType[type];

                if (!Icon || !url) return null;

                return (
                  <a target="_blank" key={index} href={url} rel="noreferrer">
                    <Icon className="w-6 h-6 text-labelSecondary hover:text-labelPrimary transition" />
                  </a>
                );
              })}
          </div>
        </section>
      </div>
    </footer>
  );
};
