import cn from 'classnames';
import { DateTime } from 'luxon';
import React from 'react';

import { formatCceSchoolDate } from '@features/cce/components/sections/roadshows/utils';
import { Tooltip } from '@features/common';
import { ArrayElement } from '@globalTypes/globals';
import { CCEBaseDocument } from '@globalTypes/prismic/documents';

type RoadShowSchool = ArrayElement<CCEBaseDocument['roadshowSchools']>['roadshowSchool'];

const SchoolInfoPopup = ({
  school: {
    data: { title: schoolName, activities, startDate, endDate, icon },
  },
}: {
  school: RoadShowSchool;
}): JSX.Element => (
  <div className="bg-[#15151E] p-6 flex rounded-[12px]">
    <section className="flex flex-col min-w-[135px] max-w-[300px] gap-4 ">
      <header className="flex gap-4">
        {icon.url && <img src={icon.url} className="w-6 h-6.5" />}
        <div>
          <div className="whitespace-nowrap">{schoolName}</div>
          <div className="text-labelTertiary">
            {startDate && endDate && `${formatCceSchoolDate(startDate)} - ${formatCceSchoolDate(endDate)}`}
          </div>
        </div>
      </header>
      <div className="border-b-1 border-[#20202D]" />
      <main className="flex flex-col gap-2">
        {activities.length > 0 ? (
          activities.map(({ activity }, index) => (
            <div key={index} className="text-labelSecondary text-sm leading-5">
              {activity}
            </div>
          ))
        ) : (
          <div className="text-center text-labelSecondary text-sm leading-5">No activities</div>
        )}
      </main>
    </section>
  </div>
);

const SchoolItem = ({
  roadshowSchool,
  activeSchoolId,
  setActiveSchoolId,
}: {
  roadshowSchool: RoadShowSchool;
  activeSchoolId: string | null;
  setActiveSchoolId: (activeSchoolId: string | null) => void;
}) => {
  const { uid } = roadshowSchool;
  const { mapX, mapY, startDate: eventStartDate, endDate: eventEndDate } = roadshowSchool.data;
  const isActive = activeSchoolId === uid;

  const onOpenTooltipSchoolId = () => setActiveSchoolId(uid);
  const onCloseTooltipSchoolId = () => setActiveSchoolId(null);

  const startDate = eventStartDate ? DateTime.fromISO(eventStartDate) : 0;
  const endDate = eventEndDate ? DateTime.fromISO(eventEndDate) : 0;
  const currentDate = DateTime.local();

  const isCompleted = currentDate > endDate;

  const positionStyles =
    mapX && mapY
      ? {
          left: `${mapX}px`,
          top: `${mapY}px`,
        }
      : {};

  return (
    <div
      className={cn(
        'absolute w-8 h-8 flex items-center justify-center hover:rounded-full cursor-pointer hover:border-1',
        isCompleted ? 'border-[#383848]' : 'border-[#8D8DA5]',
        isActive && 'rounded-full border-1',
        '-translate-x-4 -translate-y-4',
      )}
      style={positionStyles}
    >
      <Tooltip
        popupProps={{
          open: isActive,
          onOpen: onOpenTooltipSchoolId,
          onClose: onCloseTooltipSchoolId,
          contentStyle: {
            padding: '0px',
            borderRadius: '12px',
            borderWidth: '1px',
            borderColor: '#20202D',
            background: 'transparent',
            width: 'fit-content',
          },
        }}
        tooltipContent={<SchoolInfoPopup school={roadshowSchool} />}
      >
        <div
          className={cn(
            'rounded-full border-1 p-1.5 w-min',
            isCompleted ? 'border-[#383848] bg-[#292935]' : 'border-[#656581] bg-[#8888AA80]',
          )}
        >
          {isCompleted ? (
            <div className="w-2 h-2">
              <img className="w-full h-full" src="/cce/roadshows-section/checkmark.svg" alt="checkmark" />
            </div>
          ) : (
            <div className="rounded-full bg-[#C4C4D4] w-2 h-2" />
          )}
        </div>
      </Tooltip>
    </div>
  );
};

export const RoadshowsMap = ({
  roadshowSchools,
  activeSchoolId,
  setActiveSchoolId,
}: {
  roadshowSchools: CCEBaseDocument['roadshowSchools'];
  activeSchoolId: string | null;
  setActiveSchoolId: (activeSchoolId: string | null) => void;
}): JSX.Element | null => {
  return (
    <div
      className="relative w-[583px] h-[834px]"
      style={{
        backgroundImage: "url('/cce/roadshows-section/map.svg')",
      }}
    >
      {roadshowSchools.map(({ roadshowSchool }) => (
        <SchoolItem
          activeSchoolId={activeSchoolId}
          setActiveSchoolId={setActiveSchoolId}
          key={roadshowSchool.uid}
          roadshowSchool={roadshowSchool}
        />
      ))}
    </div>
  );
};
