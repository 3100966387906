import React from 'react';

import { PlaydexBannerSection } from '../components/sections/PlaydexBannerSection';
import { CceFaqSection } from '../components/sections/faq/CceFAQSection';
import { CCEHeroSection } from '../components/sections/hero/CCEHeroSection';
import { CCERoadshowsSection } from '../components/sections/roadshows/CCERoadshowsSection';
import { CCEStagesSection } from '../components/sections/stages/CCEStagesSection';
import { CCEStreamSection } from '../components/sections/stream/CCEStreamSection';

export const CceLandingTemplate = (): JSX.Element => {
  return (
    <div>
      <div className="mb-[142px] md:mb-[166px]">
        <CCEHeroSection />
      </div>
      <div className="mb-[100px] md:mb-[198px]">
        <CCEStreamSection />
      </div>
      <div className="mb-[100px] md:mb-[176px] max-w-[922px] mx-auto">
        <CCEStagesSection />
      </div>
      <div className="mb-[100px] md:mb-[145px]">
        <CCERoadshowsSection />
      </div>
      <div className="mb-[140px]">
        <CceFaqSection />
      </div>
    </div>
  );
};
