import cn from 'classnames';
import React from 'react';

import { useCceContentData } from '@features/cce';

import { SECTION_IDS } from '../../../data';

export const CCEStreamSection = (): JSX.Element | null => {
  const { isLiveStreamEnabled, liveStreamLink } = useCceContentData();

  if (!isLiveStreamEnabled || !liveStreamLink) return null;

  return (
    <section className="flex flex-col items-center" id={SECTION_IDS.LIVE_STREAM}>
      <span className="text-8 md:text-[44px] leading-[48px] font-medium mb-8">Watch live stream</span>
      <div className="relative w-min">
        <div className="relative mx-auto w-[92vw] lg:w-[923px] rounded-[28px] overflow-hidden z-50">
          <iframe
            src={liveStreamLink}
            className="w-full aspect-[923/432] !rounded-[28px]"
            allowFullScreen
            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
          />
        </div>
        <div
          className={cn(
            'absolute pointer-events-none',
            'top-0 left-0 hidden',
            'md:block lg:w-[1459px] lg:-translate-x-[275px] lg:-translate-y-[80px]',
          )}
        >
          <img className="w-full" src="/cce/live-stream-section/stars.svg" alt="stars" />
        </div>
      </div>
    </section>
  );
};
