/* eslint-disable max-len */

import { QualifiersStageActionSection, QualifiersStageExtraInfoBlock } from './components/QualifiersStage';

type StagesSectionData = {
  stages: Array<CceStageData>;
  registerFormLink: string;
};

export type CceStageData = {
  title: string;
  description: string;
  stageStatus: 'Live' | 'Soon';
  badges: Array<CceGameBadge>;
  ActionSection?: () => JSX.Element;
  StageExtraInfoBlock?: () => JSX.Element;
};

export type CceGameBadge = {
  type: 'date' | 'stageType';
  value: string;
};

export const leaderboardGameZonesData = [
  {
    region: 'NCR',
    districts: ['Capital Manila', 'Northern NCR', 'Eastern NCR', 'Southern NCR'],
    infoTooltip: '1 team per district proceed',
    blockMainShadeColor: '#F35532',
    leaderboardLink: '#',
  },
  {
    region: 'Luzon',
    districts: ['Northern Luzon', 'Southern Luzon'],
    infoTooltip: '2 team per district proceed',
    blockMainShadeColor: '#54C2D1',
    leaderboardLink: '#',
  },
  {
    region: 'Visayas',
    districts: ['Central Visayas', 'Western & Eastern Visayas'],
    infoTooltip: '1 team per district proceed',
    blockMainShadeColor: '#9AD154',
    leaderboardLink: '#',
  },
  {
    region: 'Mindanao',
    districts: ['Northern Mindanao', 'Southern Mindanao'],
    infoTooltip: '1 team per district proceed',
    blockMainShadeColor: '#ED30A1',
    leaderboardLink: '#',
  },
];
export const stagesSectionData: StagesSectionData = {
  registerFormLink:
    'https://docs.google.com/forms/d/e/1FAIpQLSfS-P55W0yWmRKt2ZXfWrEGP0tXIH31ctjZebe48ZfHw3CSHw/viewform?embedded=true',
  stages: [
    {
      title: 'Qualifiers ',
      description:
        'The qualification stage of the Mobile Legends tournament is a preliminary round where teams compete to earn a spot in the main event. During this stage, teams play a series of matches against other aspiring teams, and the top performers move on to the next round.',
      stageStatus: 'Live',
      badges: [
        {
          type: 'date',
          value: 'Apr - June',
        },
        {
          type: 'stageType',
          value: 'Leaderboard',
        },
      ],
      ActionSection: QualifiersStageActionSection,
      StageExtraInfoBlock: QualifiersStageExtraInfoBlock,
    },
    {
      title: 'Group stage: 4 groups with top 4 teams in each',
      description:
        'The qualification stage of the Mobile Legends tournament is a preliminary round where teams compete to earn a spot in the main event. During this stage, teams play a series of matches against other aspiring teams, and the top performers move on to the next round.',
      stageStatus: 'Soon',
      badges: [
        {
          type: 'date',
          value: 'Aug - Oct',
        },
        {
          type: 'stageType',
          value: 'Group stage',
        },
      ],
    },
    {
      title: 'Playoffs: Best of 8',
      description:
        'The qualification stage of the Mobile Legends tournament is a preliminary round where teams compete to earn a spot in the main event. During this stage, teams play a series of matches against other aspiring teams, and the top performers move on to the next round.',
      stageStatus: 'Soon',
      badges: [
        {
          type: 'date',
          value: '24 of November',
        },
        {
          type: 'stageType',
          value: 'Single elimination',
        },
      ],
    },
    {
      title: 'Winner crowned as PCC Champion',
      description:
        'The qualification stage of the Mobile Legends tournament is a preliminary round where teams compete to earn a spot in the main event. During this stage, teams play a series of matches against other aspiring teams, and the top performers move on to the next round.',
      stageStatus: 'Soon',
      badges: [
        {
          type: 'date',
          value: '26 of November',
        },
      ],
    },
  ],
};

export const stagesExtraComponents = {
  qualifiers: {
    ActionSection: QualifiersStageActionSection,
    ExtraInfoBlock: QualifiersStageExtraInfoBlock,
  },
};
