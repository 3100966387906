/* eslint-disable max-len */
import { ReactComponent as SchoolIcon } from '@public/cce/roadshows-section/school-icon.svg';

const mockSchoolMapItem = {
  position: '',
  Icon: SchoolIcon,
  schoolName: 'School name',
  isChecked: false,
  dates: '02 May - 03 Aug',
  activities: ['Activity 1', 'Activity 2', 'Activity 3'],
};

const schoolList = [
  {
    id: 'school-1',
    Icon: SchoolIcon,
    schoolName: 'Lyceum of the Philippines University',
    dates: '02 May - 03 Aug',
    groupName: 'NCR',
    position: 'top-[128px] left-[169px]',
    isChecked: true,
    activities: ['Activity 1', 'Activity 2', 'Activity 3'],
  },
  {
    id: 'school-2',
    Icon: SchoolIcon,
    schoolName: 'Colegio de San Juan de Letran',
    dates: '02 May - 03 Aug',
    groupName: 'NCR',
    position: 'top-[184px] left-[220px]',
    isChecked: true,
    activities: ['Activity 1', 'Activity 2', 'Activity 3'],
  },
  {
    id: 'school-3',
    Icon: SchoolIcon,
    schoolName: 'University of Santo Tomas',
    dates: '02 May - 03 Aug',
    groupName: 'NCR',
    position: 'top-[237px] left-[175px]',
    isChecked: true,
    activities: ['Activity 1', 'Activity 2', 'Activity 3'],
  },
  {
    id: 'school-4',
    Icon: SchoolIcon,
    schoolName: 'Arellano University',
    dates: '02 May - 03 Aug',
    groupName: 'NCR',
    position: 'top-[316px] left-[177px]',
    isChecked: false,
    activities: ['Activity 1', 'Activity 2', 'Activity 3'],
  },
  {
    id: 'school-5',
    Icon: SchoolIcon,
    schoolName: 'Jose Rizal University',
    dates: '02 May - 03 Aug',
    groupName: 'NCR',
    position: 'top-[361px] left-[189px]',
    isChecked: false,
    activities: ['Activity 1', 'Activity 2', 'Activity 3'],
  },
  {
    id: 'school-6',
    Icon: SchoolIcon,
    schoolName: 'Lyceum of the Philippines University',
    dates: '02 May - 03 Aug',
    groupName: 'Luzon',
    position: 'top-[359px] left-[281px]',
    isChecked: false,
    activities: ['Activity 1', 'Activity 2', 'Activity 3'],
  },
  {
    id: 'school-7',
    Icon: SchoolIcon,
    schoolName: 'Colegio de San Juan de Letran',
    dates: '02 May - 03 Aug',
    groupName: 'Luzon',
    position: 'top-[393px] left-[345px]',
    isChecked: false,
    activities: ['Activity 1', 'Activity 2', 'Activity 3'],
  },
  {
    id: 'school-8',
    Icon: SchoolIcon,
    schoolName: 'University of Santo Tomas',
    dates: '02 May - 03 Aug',
    groupName: 'Luzon',
    position: 'top-[510px] left-[323px]',
    isChecked: false,
    activities: ['Activity 1', 'Activity 2', 'Activity 3'],
  },
];

export const defaultRoadshowsSectionData = {
  title: 'School roadshows',
  description:
    'School roadshows for game tournaments are a great way to bring together students who are passionate about gaming and provide them with an opportunity to showcase their skills. ',

  schoolsGroup: [
    {
      groupName: 'NCR',
      schoolList: schoolList.filter(school => school.groupName === 'NCR'),
    },
    {
      groupName: 'Luzon',
      schoolList: schoolList.filter(school => school.groupName === 'Luzon'),
    },
  ],
  map: {
    schoolList,
  },
};
