import getConfig from 'next/config';

import { ReactComponent as DiscordIcon } from '@public/cce/footer-section/icons/discord.svg';
import { ReactComponent as FacebookIcon } from '@public/cce/footer-section/icons/facebook.svg';
import { ReactComponent as InstagramIcon } from '@public/cce/footer-section/icons/instagram.svg';
import { ReactComponent as GlobeIcon } from '@public/cce/footer-section/icons/internet.svg';
import { ReactComponent as TikTokIcon } from '@public/cce/footer-section/icons/tik-tok.svg';
import { ReactComponent as TwitchIcon } from '@public/cce/footer-section/icons/twitch.svg';
import { ReactComponent as TwitterIcon } from '@public/cce/footer-section/icons/twitter.svg';
import { ReactComponent as YoutubeIcon } from '@public/cce/footer-section/icons/youTube.svg';

const { publicRuntimeConfig } = getConfig();
const { DISCORD_SUPPORT_URL } = publicRuntimeConfig;

export const cceFooterData = {
  webLinks: [
    {
      label: 'Terms of Service',
      href: '/static/tos.html',
    },
    {
      label: 'Privacy policy',
      href: '/static/privacy_policy.html',
    },
    {
      label: 'Contact us',
      href: DISCORD_SUPPORT_URL,
    },
  ],
  socialLinksIconByType: {
    website: GlobeIcon,
    tikTok: TikTokIcon,
    instagram: InstagramIcon,
    facebook: FacebookIcon,
    discord: DiscordIcon,
    twitch: TwitchIcon,
    twitter: TwitterIcon,
    youtube: YoutubeIcon,
  },
};
