import cn from 'classnames';
import React from 'react';

import { useCceContentData } from '@features/cce';
import { DisclosurePanel } from '@features/common';
import { PrismicRichTextWrapper } from '@features/prismic';

import { SECTION_IDS } from '../../../data';

export const CceFaqSection = (): JSX.Element | null => {
  const { faqList } = useCceContentData();

  if (!faqList || faqList.length === 0) {
    return null;
  }

  return (
    <div id={SECTION_IDS.FAQS}>
      <div className="text-center mb-6">
        <span className="text-labelPrimary text-[44px] font-medium mb-2">FAQ</span>
      </div>
      <section className="overflow-hidden max-w-[800px] mx-auto">
        {faqList.map((faq, index, arr) => {
          const isLastElement = index + 1 === arr.length;
          return (
            <DisclosurePanel
              key={index}
              defaultOpen={faq.isDefaultOpen}
              className=""
              triggerClassName={cn(
                '!px-4 !py-5 !rounded-none border-[#1A1A24] hover:bg-[#13131B]',
                isLastElement ? 'border-b-0' : 'border-b-1',
              )}
              panelClassName="px-4 pt-0"
              trigger={<h3>{faq.question}</h3>}
              withAnimation={false}
            >
              <PrismicRichTextWrapper
                components={{
                  paragraph: ({ text, children }) =>
                    text && <p className="text-labelSecondary text-base mt-2 mb-4 leading-7">{children}</p>,
                }}
                field={faq.answer}
              />
            </DisclosurePanel>
          );
        })}
      </section>
    </div>
  );
};
