/* eslint-disable @typescript-eslint/ban-ts-comment */
import cn from 'classnames';
import React, { createRef, MouseEvent as ReactMouseEvent, useState } from 'react';

import { ReactComponent as MapImg } from '@public/cce/map.svg';

type DotCoordinates = {
  x: number;
  y: number;
};

export const CceMapTemplate = (): JSX.Element => {
  const [predictActiveCoordinates, setPredictActiveCoordinates] = useState<DotCoordinates | null>(null);
  const [activeDotCoordinates, setActiveDotCoordinates] = useState<DotCoordinates | null>(null);

  const parentBlockRef = createRef<HTMLDivElement>();

  const isCircleDot = (name: string) => name === 'circle';

  // @ts-ignore
  const calcDistance = element => {
    if (isCircleDot(element.tagName)) {
      // @ts-ignore
      const mapRect: DOMRect = element.getBoundingClientRect();
      const parentBlockRect: DOMRect | undefined = parentBlockRef.current?.getBoundingClientRect();
      // @ts-ignore
      const distanceLeft = Math.round(mapRect.left - parentBlockRect.left);
      // @ts-ignore
      const distanceTop = Math.round(mapRect.top - parentBlockRect.top);
      return { x: distanceLeft, y: distanceTop };
    }
    return null;
  };
  const onMapClickHandler = (event: ReactMouseEvent<SVGSVGElement, MouseEvent>) => {
    return setActiveDotCoordinates(calcDistance(event.target));
  };
  const onMapMouseMoveHandler = (event: ReactMouseEvent<SVGSVGElement, MouseEvent>) => {
    return setPredictActiveCoordinates(calcDistance(event.target));
  };

  return (
    <div className="p-4 text-lg">
      <div className="w-min h-min mx-auto relative" ref={parentBlockRef}>
        <MapImg
          onClick={onMapClickHandler}
          className="w-[583px] h-[834px] text-white"
          onMouseMove={onMapMouseMoveHandler}
        />
        {activeDotCoordinates && (
          <div
            className="absolute w-2 h-2 rounded-full bg-red-2 -translate-x-0.5 -translate-y-0.5"
            style={{ left: activeDotCoordinates.x, top: activeDotCoordinates.y }}
          />
        )}
        {predictActiveCoordinates && (
          <div
            className={cn(
              'absolute w-2 h-2 rounded-full bg-yellow-400 -translate-x-0.5 -translate-y-0.5 pointer-events-none',
            )}
            style={{ left: predictActiveCoordinates.x, top: predictActiveCoordinates.y }}
          />
        )}
      </div>
      <div className="fixed top-4 right-4 rounded-md bg-bgTertiary border-1 border-labelSecondary p-4">
        <span>Coordinates:</span>
        <div className="flex gap-4 items-center justify-between">
          <span>X:</span>
          <span>{activeDotCoordinates?.x ?? '-'}</span>
        </div>
        <div className="flex gap-4 items-center justify-between">
          <span>Y:</span>
          <span>{activeDotCoordinates?.y ?? '-'}</span>
        </div>
      </div>
    </div>
  );
};
