/* eslint-disable */
import { useState } from 'react';

import { useCceContentData } from '@features/cce';
import withSafeHydration from '@features/common/hocs/withSafeHydration';
import { useWindowSize } from '@hooks/useWindowSize';

import { RoadshowsMap } from './components/RoadshowsMap';
import { RoadshowsSchoolGroups } from './components/RoadshowsSchoolGroups';
import { defaultRoadshowsSectionData } from './defaultRoadshowsSectionData';
import { SECTION_IDS } from '../../../data';
import { CCERoadshowsSchoolDocument } from '@globalTypes/prismic/documents';

export type SchoolsByGroup = Partial<
  Record<CCERoadshowsSchoolDocument['data']['region'], Array<CCERoadshowsSchoolDocument>>
>;

const Component = (): JSX.Element => {
  const { roadshowsDescription, roadshowSchools } = useCceContentData();
  const [activeSchoolId, setActiveSchoolId] = useState<string | null>(null);

  const roadshowSchoolsForList = roadshowSchools.filter(({ roadshowSchool }) => {
    const { title, startDate, endDate, region } = roadshowSchool.data;

    return Boolean(title && startDate && endDate && region);
  });

  const roadshowSchoolsForMap = roadshowSchoolsForList.filter(({ roadshowSchool }) => {
    const { mapX, mapY } = roadshowSchool.data;

    return Boolean(mapX && mapY);
  });

  const schoolsByGroup = roadshowSchoolsForList.reduce<SchoolsByGroup>((acc, { roadshowSchool }) => {
    const { region } = roadshowSchool.data;

    acc[region] ??= [];
    return {
      ...acc,
      [region]: acc[region]?.concat(roadshowSchool),
    };
  }, {});

  const {
    orientation: { isDesktop },
  } = useWindowSize();

  return (
    <div className="flex justify-between" id={SECTION_IDS.ROADSHOWS}>
      <section className="lg:max-w-[604px]">
        <header className="flex flex-col gap-4 mb-12">
          <span className="text-8 md:text-[44px] font-medium leading-[48px]">{defaultRoadshowsSectionData.title}</span>
          <div className="text-labelSecondary text-sm md:text-[18px] leading-[28px]">
            {roadshowsDescription || defaultRoadshowsSectionData.description}
          </div>
        </header>
        <main>
          <RoadshowsSchoolGroups
            schoolsGroup={schoolsByGroup}
            setActiveSchoolId={setActiveSchoolId}
            activeSchoolId={activeSchoolId}
          />
        </main>
      </section>
      <section className="hidden lg:block">
        {isDesktop && (
          <RoadshowsMap
            activeSchoolId={activeSchoolId}
            setActiveSchoolId={setActiveSchoolId}
            roadshowSchools={roadshowSchoolsForMap}
          />
        )}
      </section>
    </div>
  );
};

export const CCERoadshowsSection = withSafeHydration(Component);
