import cn from 'classnames';
import { useEffect, useState } from 'react';

import ElModal from '@elements/ElModal';
import { GradientSpinner } from '@features/common';
import { ArrayElement } from '@globalTypes/globals';
import useAsync from '@hooks/useAsync';
import { ReactComponent as CloseIcon } from '@public/icons/custom/close.svg';

import { leaderboardGameZonesData } from './sections/stages/stagesSectionData';

type Props = {
  closeModal: () => void;
  leaderboardData: ArrayElement<typeof leaderboardGameZonesData>;
};

const regionTabs = ['Capital Manila', 'Northern NCR', 'Eastern NCR', 'Southern NCR'];

const EmptyLeaderboard = (): JSX.Element => {
  return (
    <div className="max-w-[418px] flex flex-col justify-center items-center mx-auto">
      <div className="w-[306px]">
        <img className="w-full" src="/cce/stages-section/emptyLeaderboard.svg" alt="empty-leaderboard" />
      </div>
      <div className="font-medium text-sm text-labelPrimary font-sans leading-5">
        The leaderboard will be updated once the tournament begins, so be sure to check back regularly for the latest
        updates.
      </div>
    </div>
  );
};

export const CceLeaderboardModal = ({ closeModal, leaderboardData }: Props): JSX.Element | null => {
  const { districts = [] } = leaderboardData;
  const [activeTab, setActiveTab] = useState(districts[0]);

  // Mocked data
  const [leaderboardDataState, leaderboardDataHandler] = useAsync(
    async () => new Promise(r => setTimeout(() => r([]), 1000)),
    {
      immediateCall: true,
      immediateInvocationArgs: [],
    },
  );

  useEffect(() => {
    leaderboardDataHandler();
  }, [activeTab]);

  return (
    <ElModal
      onCloseModal={closeModal}
      className="border-1 border-bgStripePrimary bg-cceLandingBg text-brandWhite max-w-[1056px] w-full"
    >
      <header className="py-5 px-6 flex justify-between items-center border-b-1 border-bgStripePrimary">
        <span className="font-medium text-2xl">Leaderboard</span>
        <CloseIcon
          className="w-6 h-6 cursor-pointer text-iconPrimary hover:text-brandWhite transition"
          onClick={closeModal}
        />
      </header>
      <main className="pt-6 px-6">
        <section className="flex gap-2 w-full overflow-auto">
          {districts.map((tab, index) => (
            <div
              className={cn(
                'px-3 py-1 text-sm font-medium rounded-[40px] border-1 cursor-pointer whitespace-nowrap',
                activeTab === tab ? 'border-[#F35532]' : 'border-[#20202C]',
              )}
              onClick={() => setActiveTab(tab)}
              key={index}
            >
              {tab}
            </div>
          ))}
        </section>
        <section className="py-5 text-center text-labelSecondary">
          {leaderboardDataState.isPending() && <GradientSpinner className="mx-auto" />}
          {leaderboardDataState.isSuccessful() && <EmptyLeaderboard />}
        </section>
      </main>
    </ElModal>
  );
};
