import cn from 'classnames';
import React from 'react';

import { PlaydexLogoImage } from '@features/header';

import { HeaderAccountButton } from './HeaderAccountButton';
import { HeaderLinks } from './HeaderLinks';

export const CceHeader = (): JSX.Element => {
  return (
    <header className="h-18 w-full relative">
      <section
        className={cn(
          'fixed top-0 max-w-[1240px] h-18 w-full mx-auto',
          'text-sm flex justify-between items-center px-4 z-[100]',
        )}
      >
        <HeaderLinks />
      </section>
    </header>
  );
};
