import cn from 'classnames';
import Image from 'next/image';
import React from 'react';

import { useCceContentData } from '@features/cce';
import { defaultHeroSectionData } from '@features/cce/components/sections/hero/defaultHeroSectionData';
import { ReactComponent as PIcon } from '@public/cce/hero-section/icons/p.svg';

import { SECTION_IDS } from '../../../data';

export const CCEHeroSection = (): JSX.Element => {
  const { descriptionBlock, statisticsBlocks } = defaultHeroSectionData;
  const { heroSectionTitle, heroSectionDescription, heroSectionStatisticsPoints } = useCceContentData();

  const statisticsList = heroSectionStatisticsPoints || statisticsBlocks;

  return (
    <div className="flex flex-col-reverse md:flex-row md:gap-[90px] justify-between" id={SECTION_IDS.HERO}>
      <section className={cn('md:max-w-[477px] flex flex-col', 'z-20 -translate-y-16 md:translate-y-0')}>
        <div
          className={cn(
            'border-1 border-[#4D211B] bg-[#241415] rounded-[28px] px-2.5 py-1',
            'flex gap-1 items-center w-fit mb-8',
          )}
        >
          <img className="w-full" src="/cce/hero-section/icons/mobile-legends.svg" alt="mlbb" />
          <span className="whitespace-nowrap text-sm">Mobile Legends: Bang Bang</span>
        </div>
        <div>
          <h1 className="text-8 md:text-[44px] font-bold mb-4 leading-[54px]">
            {heroSectionTitle || descriptionBlock.title}
          </h1>

          <div className="text-sm md:text-[18px] text-labelSecondary mb-4">
            {heroSectionDescription || descriptionBlock.description}
          </div>
        </div>

        <div className="flex justify-between md:justify-start items-center gap-12">
          {statisticsList.map(item => (
            <div key={item.label} className="flex flex-col justify-center items-center leading-none">
              <div className="text-white font-medium text-[28px] md:text-[36px] !leading-none">
                <div className="flex gap-1 items-start">
                  <span className="">{item.value}</span>
                  {item.label === 'prize pool' && <PIcon className="w-[12.5px] h-[14px] translate-y-1" />}
                </div>
              </div>
              <span>{item.label}</span>
            </div>
          ))}
        </div>
      </section>
      <section>
        <div className="relative pointer-events-none">
          <Image
            src="/cce/hero-section/bgImages/hero_section_group_images.png"
            className="pointer-events-none !z-[0] "
            width={635}
            height={538}
            alt="cce-imgs"
          />
          <Image
            className={cn(
              'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-[5]',
              'max-w-[50vw] md:max-w-[260px] lg:max-w-[360px]',
            )}
            src="/cce/hero-section/bgImages/cce-logo.svg"
            width={360}
            height={291}
            alt="cce-logo"
          />
        </div>
      </section>
    </div>
  );
};
