import cn from 'classnames';
import React, { ButtonHTMLAttributes, HTMLAttributeAnchorTarget } from 'react';

import { ConditionalWrapper } from '@elements/ConditionaltWrapper';

type NativeCceButtonProps = {
  displayAs?: 'outlined' | 'contained';
  href?: string;
  target?: HTMLAttributeAnchorTarget;
};

type CceButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & NativeCceButtonProps;

export const CceButton = (props: CceButtonProps): JSX.Element => {
  const { displayAs = 'contained', className, href, target, ...defaultBtnProps } = props;
  const classNamesByVariant = {
    outlined: 'bg-transparent hover:bg-[#2F1A1B] active:bg-[#47211E] border-[#F35532] border-1',
    contained: 'bg-[#F35532] hover:bg-[#F46748] active:bg-[#F57356]',
  };

  return (
    <ConditionalWrapper
      condition={Boolean(href)}
      wrapper={({ children }) => (
        <a target={target} href={href}>
          {children}
        </a>
      )}
    >
      <button
        {...defaultBtnProps}
        className={cn('py-2 px-[50px] text-brandWhite !rounded-lg', classNamesByVariant[displayAs], className)}
      />
    </ConditionalWrapper>
  );
};
