import cn from 'classnames';
import React, { useState } from 'react';
import { match } from 'ts-pattern';

import { useCceContentData } from '@features/cce';
import { Button, NullComponent } from '@features/common';
import { ArrayElement } from '@globalTypes/globals';
import { CCEStageDocument } from '@globalTypes/prismic/documents';
import { ReactComponent as BracketSystemIcon } from '@public/cce/stages-section/bracket-system.svg';
import { ReactComponent as CalendarIcon } from '@public/cce/stages-section/calendar.svg';
import { ReactComponent as ArrowIcon } from '@public/icons/custom/chevron-down.svg';
import { stagesExtraComponents } from '@features/cce/components/sections/stages/stagesSectionData';

const StatusBadge = ({ stageStatus }: { stageStatus: CCEStageDocument['data']['status'] }): JSX.Element => {
  return (
    <div
      className={cn(
        'rounded-[24px] border-1 text-xs leading-4 flex w-min px-2 py-1 !font-sans capitalize',
        {
          soon: 'bg-[#1F1F2C] border-bgStripePrimary text-labelPrimary',
          live: 'text-[#33FFB6] bg-[#33FFB61A] border-[#366163]',
        }[stageStatus],
      )}
    >
      {stageStatus}
    </div>
  );
};

const Badge = ({ badgeData }: { badgeData: ArrayElement<CCEStageDocument['data']['badges']> }): JSX.Element => {
  const BadgeIcon = match(badgeData.type)
    .with('date', () => CalendarIcon)
    .with('stageType', () => BracketSystemIcon)
    .otherwise(() => NullComponent);
  return (
    <div className="px-1.5 py-1 flex items-center gap-0.5 border-1 border-[#1C1C26] rounded-[24px]">
      <BadgeIcon className="w-4 h-4" />
      <span className="text-xs text-labelPrimary">{badgeData.label}</span>
    </div>
  );
};

const Separator = (): JSX.Element => (
  <div className="h-8 w-[1px] border-dashed border-l-1 border-[#F35532] translate-x-[11px] my-4" />
);

const Stage = ({
  stageIndex,
  stageData,
  stageUid,
}: {
  stageIndex: number;
  stageData: CCEStageDocument['data'];
  stageUid: string;
}): JSX.Element => {
  const [isBriefDescriptionOpen, setBriefDescriptionOpen] = useState(false);
  const { ActionSection, ExtraInfoBlock } = stagesExtraComponents[stageUid as keyof typeof stagesExtraComponents] || {};

  return (
    <div>
      <section className="flex justify-between">
        <div className="max-w-[600px] flex flex-col gap-2">
          <header className="flex gap-4">
            <div className="w-6 h-6 rounded-full flex justify-center items-center border-1 border-[#F35532]">
              <span className="font-medium">{stageIndex}</span>
            </div>
            <span className="text-[18px] font-medium">{stageData.title}</span>
            <div className="ml-auto md:hidden">
              <StatusBadge stageStatus={stageData.status} />
            </div>
          </header>
          <main className="flex flex-col gap-1">
            <div
              className={cn(
                'text-labelTertiary text-sm',
                !isBriefDescriptionOpen && stageData.isSeeMoreActive && ' !line-clamp-2',
              )}
            >
              {stageData.description}
            </div>
            {stageData.isSeeMoreActive && (
              <Button
                className={cn(
                  '!px-0 w-min whitespace-nowrap text-labelSecondary hover:text-labelPrimary transition !gap-0',
                )}
                size="xs"
                variant="transparent"
                onClick={() => setBriefDescriptionOpen(isOpen => !isOpen)}
              >
                {isBriefDescriptionOpen ? 'See less' : 'See more'}
                <ArrowIcon className={cn('w-6 h-6', isBriefDescriptionOpen ? 'rotate-180' : 'rotate-0')} />
              </Button>
            )}
          </main>
          <footer>
            <div className="flex gap-2">
              {stageData.badges.map((badgeData, index) => (
                <Badge key={index} badgeData={badgeData} />
              ))}
            </div>
          </footer>
          {ActionSection && <ActionSection />}
        </div>
        <div className="hidden md:block self-center">
          <StatusBadge stageStatus={stageData.status} />
        </div>
      </section>
      {ExtraInfoBlock && <ExtraInfoBlock />}
    </div>
  );
};

export const MainStagesGroup = (): JSX.Element => {
  const { stages } = useCceContentData();

  return (
    <div>
      {stages.map((stageItem, index) => {
        const stageIndex = index + 1;
        return (
          <React.Fragment key={stageItem.stage.uid}>
            {stageIndex > 1 && <Separator />}
            <Stage stageUid={stageItem.stage.uid} stageIndex={stageIndex} stageData={stageItem.stage.data} />
          </React.Fragment>
        );
      })}
    </div>
  );
};
