import React from 'react';

import { MainStagesGroup } from './components/MainStagesGroup';
import { SECTION_IDS } from '../../../data';

export const CCEStagesSection = (): JSX.Element => {
  return (
    <section id={SECTION_IDS.STAGES}>
      <header className="mb-1 md:mb-8 md:text-center">
        <span className="text-8 md:text-[44px] font-medium leading-[48px]">Stages</span>
      </header>
      <main>
        <MainStagesGroup />
      </main>
    </section>
  );
};
