import { ReactComponent as PIcon } from '@public/cce/hero-section/icons/p.svg';
export const defaultHeroSectionData = {
  descriptionBlock: {
    title: 'Philippine Collegiate Championship',
    description:
      'Get ready to witness Filipino MLBB players' +
      ' strength in the "Labas and Lakas" themed Mobile Legends: Bang Bang Development League - Philippines',
  },
  statisticsBlocks: [
    { label: 'regions', value: '4' },
    {
      label: 'prize pool',
      value: '500 000',
    },
    { label: 'teams', value: '500+' },
  ],
};
