import { createContext, PropsWithChildren, useContext } from 'react';

import { CCEDocument } from '@globalTypes/prismic/documents';

type ContextData = CCEDocument['data'];

const Context = createContext<ContextData | null>(null);

type CceContentDataContextProviderProps = PropsWithChildren<{
  cceContentData: CCEDocument['data'];
}>;

export const CceContentDataContextProvider = ({
  children,
  cceContentData,
}: CceContentDataContextProviderProps): JSX.Element => {
  return <Context.Provider value={cceContentData}>{children}</Context.Provider>;
};

export const useCceContentData = (): ContextData => useContext(Context) as ContextData;
