import cn from 'classnames';
import React, { PropsWithChildren } from 'react';

import { CceFooter } from '../components/sections/footer/CceFooter';
import { CceHeader } from '../components/sections/header/CCEHeader';

export const CceLandingLayout = ({ children }: PropsWithChildren): JSX.Element => {
  return (
    <div
      className={cn(
        'relative !font-walsheim text-brandWhite bg-cceLandingBg min-h-screen scroll-smooth overflow-hidden',
      )}
    >
      <div
        className={cn(
          'absolute z-[110] -top-[45px] -right-[145px] bg-[#F35532]/30 w-[556px] h-[556px] rounded-full blur-[200px]',
          'mix-blend-normal pointer-events-none hidden md:block',
        )}
      />
      <div
        style={{ backgroundImage: "url('/cce/hero-section/bgImages/section_bg.jpg')" }}
        className="w-screen z-0 h-[120vh] bg-cover md:h-[750px] absolute top-0 z-0 opacity-10"
      />
      <section className="max-w-[1240px] mx-auto">
        <CceHeader />
        <main className="flex flex-col mt-20 px-4">{children}</main>
      </section>
      <CceFooter />
    </div>
  );
};
