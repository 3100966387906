import {
  logCCEClickOnPlaydexTournamentsLinkAmplitudeEvent,
  logCCEOpenedAmplitudeEvent,
  logCCEStartedTournamentRegistrationAmplitudeEvent,
} from './amplitudeEvents';
import { CCEAnalyticsEvent } from './types';

type CCEAnalyticsEventWithPayload =
  | {
      readonly type: CCEAnalyticsEvent.CCE_OPENED;
    }
  | {
      readonly type: CCEAnalyticsEvent.STARTED_TOURNAMENT_REGISTRATION;
    }
  | {
      readonly type: CCEAnalyticsEvent.CLICK_ON_PLAYDEX_TOURNAMENTS_LINK;
    };

export const dispatchCCEAnalyticsEvent = ({ type }: CCEAnalyticsEventWithPayload): void => {
  switch (type) {
    case CCEAnalyticsEvent.CCE_OPENED:
      logCCEOpenedAmplitudeEvent();
      break;
    case CCEAnalyticsEvent.STARTED_TOURNAMENT_REGISTRATION:
      logCCEStartedTournamentRegistrationAmplitudeEvent();
      break;
    case CCEAnalyticsEvent.CLICK_ON_PLAYDEX_TOURNAMENTS_LINK:
      logCCEClickOnPlaydexTournamentsLinkAmplitudeEvent();
      break;
    default:
      return;
  }
};
