import cn from 'classnames';
import React, { useState } from 'react';

import ElModal from '@elements/ElModal';
import { ModalCloseButton, Tooltip } from '@features/common';
import { ArrayElement } from '@globalTypes/globals';
import { useBoolean } from '@hooks/useBoolean';
import { ReactComponent as LeftIcon } from '@public/cce/stages-section/chevron.svg';
import { ReactComponent as InfoIcon } from '@public/cce/stages-section/info-icon.svg';
import { ReactComponent as MarkerIcon } from '@public/cce/stages-section/marker.svg';

import { cceDocumentationHTMLStatic } from '../../../../static/cceDocumentationStatic';
import { CceButton } from '../../../CceButton';
import { CceLeaderboardModal } from '../../../CceLeaderboardModal';
import { leaderboardGameZonesData } from '../stagesSectionData';

export const QualifiersStageActionSection = (): JSX.Element => {
  const [isModalOpened, openModal, closeModal] = useBoolean(false);
  return (
    <>
      <div className="grid grid-cols-2 md:flex gap-4 mt-2">
        <CceButton displayAs="outlined" target="_blank" onClick={openModal}>
          Read rules
        </CceButton>
      </div>
      {isModalOpened && (
        <ElModal
          onCloseModal={closeModal}
          className={cn('border-1 border-bgStripePrimary bg-cceLandingBg text-brandWhite w-full', 'max-w-[1056px]')}
        >
          <div className="h-screen md:max-h-[600px] p-4">
            <header className="md:hidden flex justify-end w-full mb-2">
              <ModalCloseButton onClick={closeModal} />
            </header>
            <div
              className="!h-full overflow-auto p-4"
              dangerouslySetInnerHTML={{ __html: cceDocumentationHTMLStatic }}
            />
          </div>

          {/* TODO: Research work with formatting of markdown content */}
          {/*<ReactMarkdown skipHtml={false} className="md:max-h-[400px] overflow-auto p-2">*/}
          {/*  {cceDocumentationStatic}*/}
          {/*</ReactMarkdown>*/}
        </ElModal>
      )}
    </>
  );
};

export const QualifiersStageExtraInfoBlock = (): JSX.Element => {
  const [activeLeaderboard, setActiveLeaderboard] = useState<ArrayElement<typeof leaderboardGameZonesData> | null>(
    null,
  );
  const closeLeaderboardModal = () => setActiveLeaderboard(null);
  return (
    <>
      <div className="flex gap-2 mt-6 w-full overflow-auto">
        {leaderboardGameZonesData.map(leaderboardData => (
          <div
            className={cn(
              'bg-[#0F0F15] border-bgStripePrimary border-1 rounded-[16px] flex-1 flex flex-col gap-4 p-6',
              'overflow-hidden relative min-w-[224px]',
            )}
            key={leaderboardData.region}
          >
            <div
              className="absolute w-15 h-15 rounded-full -top-3 -right-3 blur-[75px]"
              style={{ backgroundColor: leaderboardData.blockMainShadeColor }}
            />
            <header className="relative flex items-center">
              <MarkerIcon className="w-4 h-4 mr-2" />
              <span>{leaderboardData.region}</span>
              {leaderboardData.infoTooltip && (
                <Tooltip tooltipContent={leaderboardData.infoTooltip} position="top center">
                  <div className="ml-auto">
                    <InfoIcon className="w-6 h-6 text-labelTertiary" />
                  </div>
                </Tooltip>
              )}
            </header>
            <main className="flex flex-col gap-1">
              {leaderboardData.districts.map(district => (
                <div className="text-labelSecondary text-sm leading-5" key={district}>
                  {district}
                </div>
              ))}
            </main>
            <footer className="mt-auto">
              {leaderboardData.leaderboardLink && (
                <div
                  className={cn(
                    'py-1.5 text-sm  transition text-labelSecondary hover:text-labelSecondary/60',
                    'font-medium flex gap-1 items-center cursor-pointer',
                  )}
                  onClick={() => setActiveLeaderboard(leaderboardData)}
                >
                  <span>See leaderboard</span>
                  <LeftIcon className="w-4 h-4" />
                </div>
              )}
            </footer>
          </div>
        ))}
      </div>
      {activeLeaderboard && (
        <CceLeaderboardModal leaderboardData={activeLeaderboard} closeModal={closeLeaderboardModal} />
      )}
    </>
  );
};
