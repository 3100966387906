export enum SECTION_IDS {
  HERO = 'hero-section',
  LIVE_STREAM = 'live-stream-section',
  STAGES = 'stages-section',
  ROADSHOWS = 'roadshows-section',
  FAQS = 'faq-section',
}

export const sectionsMetaData = [
  {
    id: SECTION_IDS.LIVE_STREAM,
    name: 'Live stream',
  },
  { id: SECTION_IDS.STAGES, name: 'Stages' },
  {
    id: SECTION_IDS.ROADSHOWS,
    name: 'Roadshows',
  },
  {
    id: SECTION_IDS.FAQS,
    name: 'FAQ',
  },
];
