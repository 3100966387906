import cn from 'classnames';
import React from 'react';

import { useWindowSize } from '@hooks/useWindowSize';
import { ReactComponent as ChevronIcon } from '@public/cce/roadshows-section/chevronSmall.svg';

import { SchoolsByGroup } from '../CCERoadshowsSection';
import { formatCceSchoolDate } from '../utils';

type Props = {
  schoolsGroup: SchoolsByGroup;
  activeSchoolId: string | null;
  setActiveSchoolId: (id: string | null) => void;
};

export const RoadshowsSchoolGroups = ({ schoolsGroup, activeSchoolId, setActiveSchoolId }: Props): JSX.Element => {
  const {
    orientation: { isDesktop },
  } = useWindowSize();

  const schoolGroupEntries = Object.entries(schoolsGroup);

  return (
    <div className="flex flex-col gap-2 lg:max-w-[500px]">
      {schoolGroupEntries.map(([region, schoolList]) => (
        <div className="flex flex-col gap-2" key={region}>
          <span className="text-[20px] lg:text-base text-white font-medium">{region}</span>
          <div className="flex flex-col gap-2 max-[500px]">
            {schoolList.map(roadshowSchool => {
              const { title: schoolName, icon, startDate, endDate, activities } = roadshowSchool.data;
              const uid = roadshowSchool.uid;

              const isActive = uid === activeSchoolId;

              const schoolIcon = icon.url && <img src={icon.url} className="w-6 h-6.5" />;

              const dates =
                startDate && endDate && `${formatCceSchoolDate(startDate)} - ${formatCceSchoolDate(endDate)}`;

              const onOpenTooltipSchoolId = () => isDesktop && setActiveSchoolId(uid);
              const onCloseTooltipSchoolId = () => isDesktop && setActiveSchoolId(null);
              const toggleActiveTooltip = () => setActiveSchoolId(isActive ? null : uid);

              return (
                <div
                  className={cn(
                    'bg-[#111118]  border-1 rounded-[12px] p-4 cursor-pointer border-[#20202D]',
                    isActive ? 'lg:border-[#545478]' : 'border-[#20202D]',
                  )}
                  key={uid}
                  onMouseEnter={onOpenTooltipSchoolId}
                  onMouseLeave={onCloseTooltipSchoolId}
                  onClick={toggleActiveTooltip}
                >
                  <div className="hidden lg:flex gap-4 items-center">
                    {schoolIcon}
                    <span className="font-medium">{schoolName}</span>
                    <span className="ml-auto text-[#989DB3]">{dates}</span>
                  </div>
                  <div className="lg:hidden">
                    <header className="flex gap-4 items-center">
                      {schoolIcon}
                      <div className="flex flex-col">
                        <span className="text-sm font-medium">{schoolName}</span>
                        <span className="text-xs text-[#989DB3]">{dates}</span>
                      </div>
                      <ChevronIcon className={cn('ml-auto w-6 h-6', isActive && 'rotate-180')} />
                    </header>
                    {isActive && (
                      <main className="mt-4 p-4 flex flex-col gap-2 border-t-1 border-[#20202D]">
                        {activities.length > 0 ? (
                          activities.map(({ activity }) => (
                            <div className="text-sm text-labelSecondary" key={activity}>
                              {activity}
                            </div>
                          ))
                        ) : (
                          <div className="text-center text-labelSecondary text-sm leading-5">No activities</div>
                        )}
                      </main>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ))}
    </div>
  );
};
